<template>
  <div v-if="bind.boolCarregado">
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Configuração Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-tipo">
        Gerenciar Tipo Consulta
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-status">
        Gerenciar Status
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData.agendamentoConfiguracao.strValor"
      :handlerSave="strTab == 'tab-cadastro' ? salvar : null"
      v-if="!this.$root.$session.loadingContent"
      disableFixedSave
    >
      <cadastro-geral
        v-if="strTab == 'tab-cadastro'"
        :formData="formData"
        :key="'cadastro-geral-' + $root.$session.versao"
      />

      <parametro
        v-if="strTab == 'tab-tipo'"
        strChave="consultaGrupo"
        :key="'tipo-consulta-' + $root.$session.versao + strKey"
      />
      <parametro
        v-if="strTab == 'tab-status'"
        strChave="consultaStatus"
        :key="'status-' + $root.$session.versao + strKey"
      />
    </ed-form>
  </div>
</template>

<script>
import Parametro from "@/components/common/parametro/editar";
import CadastroGeral from "./partials/geral.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    Parametro,
    CadastroGeral,
    EdForm,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
        arrayDiasAtendimento: [
          { intId: 0, strNome: "Domingo" },
          { intId: 1, strNome: "Segunda-Feira" },
          { intId: 2, strNome: "Terça-Feira" },
          { intId: 3, strNome: "Quarda-Feira" },
          { intId: 4, strNome: "Quinta-Feira" },
          { intId: 5, strNome: "Sexta-Feira" },
          { intId: 6, strNome: "Sábado" },
        ],
      },
      strTab: "tab-cadastro",
      strKey: this.$utilidade.randomString(),
      formData: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/Parametro", {
          strChave: "agendamentoConfiguracao",
          boolBuscarSomenteAtivo: false,
        }),
      ]).then(([objConfiguracao]) => {
        this.$root.$dialog.loading(false);

        if (objConfiguracao.status == 200 && objConfiguracao.result) {
          if (!objConfiguracao.result.strValor) {
            objConfiguracao.result.strValor = {};
          }
          this.formData = {
            agendamentoConfiguracao: Object.assign(objConfiguracao.result, {}),
          };
        }

        this.bind.boolCarregado = true;
      });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Common/Parametro", this.formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          this.strKey = this.$utilidade.randomString();
          this.initialize();
        }
      });
    },
  },
};
</script>
