<template>
  <div class="row mb-3">
    <ed-consulta-config
      :intConsultaId="$route.params.intId"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdConsultaConfig from "@/components/consulta/configuracao/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdConsultaConfig},
  beforeDestroy() {

  },
  mounted() {

  },
  created() {},
  data() {
    return {

    };
  },
  watch: {},
  computed: {
  
  },
  methods: {

  },
};
</script>
